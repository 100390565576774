import React, { useEffect, useState } from "react";

import {
  AppLayout,
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ColumnLayout,
  Container,
  Form,
  Header,
  Modal,
  PromptInput,
  Select,
  SpaceBetween,
  Spinner,
  Textarea,
} from "@amzn/awsui-components-react-v3";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getUserAlias } from "../amplify/AmplifyCache";
import { DEFAULT_SKILL_DROPDOWN_VALUE, DISCLAIMER_POP_UP_CONTENT, skillsMapping } from "../common/constants";
import { ChatMessage, ChatMessageProps } from "../components/chatbot/message/ChatMessage";
import finneyLogo from "../components/image/FinneyLogo.png";
import { HeroHeader } from "../components/layout/heroheader/HeroHeader";
// eslint-disable-next-line import/order
import TopNav from "../components/navigation/top-navigation/TopNavigation";

// Highlight code blocks

import MarkdownCodeBlockHighlighter from "../rendering-helpers/MarkdownCodeBlockHighlighter";
import "./style.scss";
import { getFinQAMetadata, lambdaUrlStreaming } from "../utils/ApiHelper";
import { invokeMetadataEndpoint } from "../utils/ApiHelper";
import { getAcknowledgement, getSkills, populateIntroTextForSkill } from "../utils/CommonUtils";

export interface HomeProps {
  page?: string;
}

export default function Home(props: HomeProps) {
  const userAlias: string = getUserAlias();
  const { skillId } = useParams();
  const navigate = useNavigate();
  const [finneySkills, setFinneySkills] = useState<any>([]);

  // == Constants
  const [introText, setIntroText] = useState<string>("");

  // == States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [debugMessages, setDebugMessages] = useState<string[]>([]);
  const addToDebugMessages = (dbgMsg: string) => {
    setDebugMessages((prevDebugMessages) => [...prevDebugMessages, dbgMsg]);
  };

  const [userTask, setUserTask] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [conversationId, setConversationId] = useState<string>("");
  const [chatbotPersona, setChatbotPersona] = useState({
    label: DEFAULT_SKILL_DROPDOWN_VALUE,
    value: DEFAULT_SKILL_DROPDOWN_VALUE,
  });
  const [chatPanelContents, setChatPanelContents] = useState<string>("");

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const _autoSpeak = searchParams.get("autoSpeak");
  const [autoSpeak, setAutoSpeak] = useState<boolean>(_autoSpeak === "true");

  // chat history with in same convo
  const [messageHistory, setMessageHistory] = useState<ChatMessageProps[]>([]);

  // finqna-Metadata
  const [finqnaMetadata, setFinqnaMetadata] = useState<any>([]);

  const [businessTenants, setBusinessTenants] = useState<ButtonDropdownProps[]>([]);
  const [businessTenantSelected, setBusinessTenatSelected] = useState<string>("Please Select");

  const [docTypes, setDocTypes] = useState<ButtonDropdownProps[]>([]);
  const [docTypeSelected, setDocTypeSelected] = useState<string>("Please Select");

  const [docs, setDocs] = useState<ButtonDropdownProps[]>([]);
  const [docSelected, setDocSelected] = useState<string>("ALL");

  const [acknowledged, setAcknowledged] = React.useState(true);

  useEffect(() => {
    const skill = skillsMapping[chatbotPersona.value];
    setIntroText(skill.skillDescription);
    setUserTask(skill.exampleTask);
  }, [chatbotPersona.value]);

  useEffect(() => {
    if (skillId) {
      populateIntroTextForSkill(skillId, setChatbotPersona, setIntroText, setUserTask);
    } else {
      setIntroText(skillsMapping[DEFAULT_SKILL_DROPDOWN_VALUE].skillDescription);
      setChatbotPersona({ label: DEFAULT_SKILL_DROPDOWN_VALUE, value: DEFAULT_SKILL_DROPDOWN_VALUE });
    }
  }, [skillId]);

  // == Bootstrap
  useEffect(() => {
    // set skill dropdown if user uses a direct link
    if (skillId) {
      populateIntroTextForSkill(skillId, setChatbotPersona, setIntroText, setUserTask);
    }
    setMessageHistory([]);
    getSkills(setFinneySkills);
    getFinQAMetadata(userAlias, setFinqnaMetadata, setBusinessTenants);
    getAcknowledgement(setAcknowledged);
  }, []);

  // == Handlers
  /**
   * Call backend LLM wrapper
   * @returns
   */
  const processUserTask = async () => {
    // :: Validations
    if (isLoading) {
      return;
    }
    if (!question) {
      alert("Please enter a question/task. Looks like you did not put in anything for Finney.");
      return;
    }
    setUserTask("");
    setQuestion("");
    setIsLoading(true);

    const _messageHistory = messageHistory;
    _messageHistory.push({
      autoSpeak: autoSpeak,
      timestamp: new Date().toISOString(),
      messageId: `dummy-id-${Date.now()}`, // fake it because it does not exist yet
      conversationId: conversationId,
      message: question,
      skill: chatbotPersona.label,
      staticReferenceSources: [],
      referencedSources: [],
      citations: [],
      owner: {
        isSelf: true,
        isAI: false,
        avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
        name: userAlias + "@",
        alias: userAlias,
      },
    } as ChatMessageProps);
    setMessageHistory(_messageHistory);
    let chatPanelDom = document.getElementById("chat-panel")!;
    // auto scroll to bottom
    chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

    const userInput = question;
    const start = Date.now();
    addToDebugMessages(
      `${new Date().toLocaleString()} - CLIENT - INFO - Sent to Finney message "${userInput.substring(0, 36)}..."`
    );

    try {
      const { messageId, _conversationId, finalAnswer, referencedSources } = await lambdaUrlStreaming(
        userInput,
        conversationId,
        chatbotPersona.value,
        setChatPanelContents,
        chatPanelContents,
        addToDebugMessages,
        autoSpeak,
        businessTenantSelected,
        docTypeSelected,
        docSelected
      );
      setConversationId(_conversationId);
      // finney is DONE, add its new answer to chatPanelMsgHistory
      const _messageHistory = messageHistory;
      _messageHistory.push({
        messageId: messageId,
        conversationId: _conversationId,
        autoSpeak: autoSpeak,
        message: finalAnswer,
        skill: chatbotPersona.label,
        timestamp: new Date().toISOString(),
        citations: [],
        staticReferenceSources:
          chatbotPersona.value === "sna-web-mec"
            ? ["https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home"]
            : [],
        referencedSources: referencedSources,
        owner: {
          isSelf: false,
          isAI: true,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
          name: userAlias + "@",
          alias: userAlias,
        },
      } as ChatMessageProps);
      setMessageHistory(_messageHistory);

      // auto scroll to bottom
      chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

      const duration = ((Date.now() - start) / 1000).toFixed(2); // secs
      addToDebugMessages(
        `${new Date().toLocaleString()} - CLIENT - INFO - Done. Took ${duration} seconds to get answer from LLM\n`
      );
      setIsLoading(false);
    } catch (error) {
      console.warn("Failed to parse LLM answer, error was", error);
      const errorMsg = `${new Date().toLocaleString()} - CLIENT - ERROR - Failed to parse LLM answer, error was: ${error}\n`;
      addToDebugMessages(errorMsg);
      setIsLoading(false);
    }
  };

  function checkButtonDisable(skillId: string): boolean {
    if (isLoading || question === "" || props.page === "Home") {
      return true;
    } else if (skillId === "fin-qna") {
      return businessTenantSelected === "Please Select" || docTypeSelected === "Please Select";
    } else {
      return false;
    }
  }

  // TODO: will be enabled for smart routing
  // function handleNewConversation() {
  //   setConversationId("");
  //   setQuestion("");
  //   setUserTask("");
  //   setIsLoading(false);
  //   navigate(`/skills/${chatbotPersona.value}?autoSpeak=${autoSpeak}`);
  // }

  return (
    <>
      <Modal
        visible={!acknowledged}
        size="max"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={() => {
                  invokeMetadataEndpoint("set-acknowledgement");
                  setAcknowledged(true);
                }}
              >
                I Agree
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Finney Usage Guidelines"
      >
        <ReactMarkdown>{DISCLAIMER_POP_UP_CONTENT}</ReactMarkdown>
      </Modal>
      <div id="b">
        <div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
          <TopNav
            userAlias={getUserAlias()}
            issueLink={skillsMapping[chatbotPersona.value].issueLink}
            aboutLink={skillsMapping[chatbotPersona.value].aboutLink}
            skillContactLink={skillsMapping[chatbotPersona.value].skillLink}
            skillLabel={skillsMapping[chatbotPersona.value].skillLabel}
          />
        </div>
        <HeroHeader
          category="Welcome to"
          title={props.page === "Home" ? "Finney" : chatbotPersona.label}
          subtitle={skillsMapping[chatbotPersona.value].skillSubTitle}
          description={skillsMapping[chatbotPersona.value].skillHeaderDescription}
        />
      </div>
      <AppLayout
        navigationHide={true}
        disableContentPaddings={false}
        toolsHide={true}
        maxContentWidth={Number.MAX_VALUE}
        content={
          <>
            <Form
              actions={
                <div>
                  {/* main buttons */}
                  <SpaceBetween size={"s"} direction="horizontal">
                    {isLoading ? <Spinner size="big" /> : null}
                    {props.page === "Skill" ? (
                      <Select
                        onChange={async ({ detail }) => {
                          setChatbotPersona(detail.selectedOption as any);
                          navigate(`/skills/${detail.selectedOption.value}?autoSpeak=${autoSpeak}`);
                        }}
                        selectedOption={chatbotPersona}
                        options={finneySkills}
                      />
                    ) : null}
                    {/* Handling for FinQnA use-case Start */}
                    {/* if persona id is fin-qna-b01 then show the additional dropdown */}
                    {
                      // Tenant dropdown
                      props.page === "Skill" && chatbotPersona.value === "fin-qna" && businessTenants.length > 0 ? (
                        <ButtonDropdown
                          items={businessTenants}
                          onItemClick={(e) => {
                            setBusinessTenatSelected(e?.detail?.id === "none" ? "ALL" : e?.detail?.id);

                            // update the fDocTypes by selecting the obj.key === e?.detail?.id
                            // grab its .value as an array
                            const selectedTenantObj = finqnaMetadata.filter((tenant: { key: string }) => {
                              return tenant.key === e?.detail?.id;
                            });
                            const _docTypes = selectedTenantObj[0].value.map((docType: any) => {
                              return {
                                id: docType.key,
                                text: docType.key,
                              };
                            });
                            setDocTypes(_docTypes);
                            // reset the fDocTypeSelected and fDocs
                            setDocTypeSelected("Please Select");
                            setDocs([]);
                            setDocSelected("Please Select");
                          }}
                        >
                          Team: {businessTenantSelected}
                        </ButtonDropdown>
                      ) : null
                    }

                    {props.page === "Skill" && chatbotPersona.value === "fin-qna" && docTypes.length > 0 ? (
                      <ButtonDropdown
                        items={docTypes}
                        onItemClick={(e) => {
                          setDocTypeSelected(e?.detail?.id === "none" ? "ALL" : e?.detail?.id);

                          // update the fDocs by selecting the obj.key === e?.detail?.id
                          // grab its .value as an array
                          const selectedTenantObj = finqnaMetadata.filter((tenant: { key: string }) => {
                            return tenant.key === businessTenantSelected;
                          });
                          const selectedDocTypeObj = selectedTenantObj[0].value.filter((docType: any) => {
                            return docType.key === e?.detail?.id;
                          });
                          const _docs = selectedDocTypeObj[0].value.map((doc: any) => {
                            // iterate on the .value as an array
                            return {
                              id: doc,
                              text: doc,
                            };
                          });
                          setDocs(_docs);
                          // reset the fDocSelected
                          setDocSelected("All " + e?.detail?.id + " Documents");
                        }}
                      >
                        Doc Type: {docTypeSelected}
                      </ButtonDropdown>
                    ) : null}

                    {props.page === "Skill" && chatbotPersona.value === "fin-qna" && docs.length > 0 ? (
                      <ButtonDropdown
                        items={docs}
                        onItemClick={(e) => {
                          setDocSelected(e?.detail?.id === "none" ? "ALL" : e?.detail?.id);
                        }}
                      >
                        Doc: {docSelected}
                      </ButtonDropdown>
                    ) : null}
                    {props.page !== "Home" ? (
                      <Button
                        variant="primary"
                        onClick={processUserTask}
                        disabled={checkButtonDisable(chatbotPersona.value)}
                      >
                        Finney Go!
                      </Button>
                    ) : null}
                    {/* TODO: will be enabled for smart routing */}
                    {/* props.page !== "Home" ? (
                      <Button variant="primary" onClick={handleNewConversation} disabled={isLoading}>
                        New Conversation!
                      </Button>
                    ) : null */}
                  </SpaceBetween>
                </div>
              }
            >
              {/* Separate section to make the JSON textarea big enough */}
              <Container>
                <ColumnLayout columns={1}>
                  <div
                    id="chat-panel"
                    style={{
                      position: "relative",
                      height: "-webkit-fit-content",
                      overflow: "scroll",
                      padding: 4,
                    }}
                  >
                    {
                      <>
                        <SpaceBetween direction={"horizontal"} size={"s"}>
                          {/* AI avatar */}
                          <span className={"image-container"}>
                            <img width={32} src={finneyLogo} alt="Finney Logo" />
                          </span>
                          <ReactMarkdown components={{ code: MarkdownCodeBlockHighlighter }} className={"skill-page"}>
                            {introText}
                          </ReactMarkdown>
                        </SpaceBetween>
                        {props.page === "Home" && (
                          <div style={{ position: "absolute", top: 10, right: 10 }}>
                            <Container
                              header={<Header variant="h2">Getting started</Header>}
                              className={"right-sidebar"}
                            >
                              <Box padding={{ bottom: "m" }} variant="h5">
                                Select a Skill to interact with Finney.
                              </Box>
                              <Select
                                onChange={async ({ detail }) => {
                                  setChatbotPersona(detail.selectedOption as any);
                                  navigate(`/skills/${detail.selectedOption.value}?autoSpeak=${autoSpeak}`);
                                }}
                                selectedOption={chatbotPersona}
                                options={finneySkills}
                              />
                            </Container>
                          </div>
                        )}
                        <>
                          <SpaceBetween direction="vertical" size="m">
                            {/* Messages History */}
                            {messageHistory.map((message, idx) => (
                              <ChatMessage
                                key={idx}
                                messageId={message.messageId}
                                timestamp={message.timestamp}
                                skill={chatbotPersona.label}
                                message={message.message}
                                owner={message.owner}
                                autoSpeak={autoSpeak}
                                citations={message.citations}
                                staticReferenceSources={message.staticReferenceSources}
                                referencedSources={message.referencedSources}
                              />
                            ))}

                            {
                              /** Realtime answer from AI */ !chatPanelContents ? null : (
                                <Container>
                                  <SpaceBetween alignItems="start" direction="horizontal" size="xs">
                                    {/* AI avatar */}
                                    <span>
                                      <img width={32} src={finneyLogo} alt="Finney" />
                                      <div />
                                      {isLoading ? <Spinner size="big" /> : null}
                                    </span>
                                    {/* AI message */}
                                    <SpaceBetween direction="vertical" size="m">
                                      {/* TODO hardcoded maxWidth=1100 to make it looks nice -- need to refactor */}
                                      <div style={{ maxWidth: 1100 }}>
                                        <ReactMarkdown
                                          children={chatPanelContents}
                                          components={{ code: MarkdownCodeBlockHighlighter }}
                                        />
                                      </div>
                                    </SpaceBetween>
                                  </SpaceBetween>
                                </Container>
                              )
                            }
                          </SpaceBetween>
                        </>
                      </>
                    }
                  </div>

                  {/* A - User input message */}
                  {props.page === "Home" ? null : (
                    <PromptInput
                      onChange={(e: any) => setQuestion(e.detail.value)}
                      onAction={processUserTask}
                      value={question}
                      actionButtonIconName="gen-ai"
                      actionButtonAriaLabel="Submit prompt"
                      minRows={4}
                      maxRows={12}
                      placeholder={userTask}
                    />
                  )}
                </ColumnLayout>
              </Container>
            </Form>

            {/* What's going on? -- Display Debug/Trace messages */}
            {props.page !== "Home" ? (
              <div style={{ paddingBottom: 16, paddingTop: 0 }}>
                <h4>What's Going On?</h4>
                {/* <Textarea name="whatsgoingon" value={whatsGoingOn} rows={4} readOnly></Textarea> */}
                <Textarea
                  id="debug-messages-panel"
                  name="debugMessages"
                  value={debugMessages.join("\n")}
                  rows={4}
                  readOnly
                ></Textarea>
              </div>
            ) : null}
          </>
        }
      ></AppLayout>
    </>
  );
}

import { invokeMetadataEndpoint } from "./ApiHelper";
import { skillsMapping } from "../common/constants";

/**
 * Populates the Intro Text and Example Task based on the skill
 * @param skillId
 * @param setChatbotPersona
 * @param setIntroText
 * @param setUserTask
 */
export function populateIntroTextForSkill(
  skillId: string,
  setChatbotPersona: (arg0: { label: any; value: string }) => void,
  setIntroText: (arg0: any) => void,
  setUserTask: (arg0: any) => void
) {
  const skill = skillsMapping[skillId];
  if (skill) {
    setChatbotPersona({ label: skill.skillName, value: skillId });
    setIntroText(skill.skillDescription);
    setUserTask(skill.exampleTask);
  }
}

/**
 * Retrieves available skills for web when page loads
 */
export async function getSkills(setFinneySkills: any) {
  const skills = await invokeMetadataEndpoint("list-available-skills");
  const finneySkills = transformAvailableSkillsToDropdownObjects(skills);
  setFinneySkills(finneySkills);
}

function transformAvailableSkillsToDropdownObjects(
  data: { skillName: string; skillId: string }[]
): { label: string; value: string }[] {
  return data.map((item) => ({
    label: item.skillName,
    value: item.skillId,
  }));
}

/**
 * Retrieves the status of user disclaimer acknowledgement
 */
export async function getAcknowledgement(setAcknowledged: any) {
  const acknowledgement = (await invokeMetadataEndpoint("get-acknowledgement")).body;
  setAcknowledged(acknowledgement.acknowledged);
}

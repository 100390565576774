export const DEFAULT_SKILL_DROPDOWN_VALUE = "Please Select a Skill";
export const aboutLink = "https://w.amazon.com/bin/view/WWCFT/Products/Finney/Home";

export const FINQA_DISCLAIMER =
  "FinQ&A uses GenAI to answer your questions. It may produce inaccurate information but your feedback will help it improve. Please allow up to 30 seconds to get your response.";
export const SA_WEBMEC_FINNEY_DISCLAIMER =
  "S&A WebMEC Finney uses GenAI to answer your questions. It may produce inaccurate information but your feedback will help it improve. Please allow up to 30 seconds to get your response.";
export const DATA_CATALOG_SEARCH_DISCLAIMER =
  "Please note that Data Catalog Search is designed to provide information and support based on its programming and available data.";

//TODO: Need to have templates from the skill owners and update
export const issueLink = "https://issues.amazon.com/issues/create?template=6fe9db99-62ad-4b77-93ef-a44e52bb2a4e";

export const skillsMapping: any = {
  "Please Select a Skill": {
    skillName: "Please Select a Skill",
    skillId: "Please Select a Skill",
    skillSubTitle:
      "A powerful AI Assistant to help Amazon Finance Professionals find data, search for information, and more....",
    skillHeaderDescription: "",
    skillDescription:
      "### Finney\n\n" +
      "Hi, I am Finney! \n\n" +
      "I can help you on questions related to below domain:\n\n" +
      "1. Locate and answer any questions on text data from the listed financial documents in **FinQ&A** skill.\n" +
      "- Example Question: _What was Q2G AP Balance in 2023?_\n\n" +
      "2. Search active datasets from multiple finance organizations in **Data Catalog Search** skill.\n" +
      "- Example Question: _Which table contains dimensional details for ASINs on the Amazon marketplace?_\n\n" +
      "3. Get answers to your questions on topics related to month-end-close tool in **S&A WebMEC** skill.\n" +
      "- Example Question: _Could you share the steps to create Allocation Automation Rule?_\n\n" +
      "### General Instructions\n" +
      "- Please select a skill that is relevant to your need from the list of available skills.\n" +
      "- Once the skill is selected, you will be automatically directed to the specific Finney Skill page.\n" +
      "- Type your question on that skill page and click **Finney Go**.\n" +
      "- Please allow up to 30 seconds for some Finney skills to return an answer.\n" +
      "### Disclaimer:\n" +
      "Finney uses generative AI and it may give inaccurate or inappropriate responses. Please review and validate the information before you use it. For more information see [Responsible AI](https://w.amazon.com/bin/view/WWCFT/Products/Finney/ResponsibleAIPolicy/).",
    exampleTask: "I got access denied for Discovery system. What should I do?",
    skillLink: "https://w.amazon.com/bin/view/WWCFT/Products/Finney/Home#HMeettheTeam",
    skillLabel: "Finney",
    aboutLink: aboutLink,
    issueLink: issueLink,
  },

  "fin-qna": {
    skillName: "FinQ&A",
    skillId: "fin-qna",
    skillSubTitle: "Your finance question and answer tool",
    skillHeaderDescription: FINQA_DISCLAIMER,
    skillDescription:
      "**FinQ&A is a powerful GenAl assistant that can answer questions on a wide range of FP&A topics. To get the most relevant and accurate responses, it's helpful to provide some additional detail and context in your questions**.\n\n" +
      `##### Disclaimer: ${FINQA_DISCLAIMER}\n\n` +
      "### Knowledge Repository\n\n" +
      "1. WW Ops FP&A: 2024 Q1 and 2023 Guidance, Month-End Close, and OP Documents\n\n" +
      "2. WW Stores FP&A: 2024 Q1-Q2 and 2023 Guidance, Month-End Close, and Day 10 Note Documents **Q3G Charlie (7/24) has been added as well**\n\n" +
      "3. **International Stores FP&A: 2024 Q1-Q2 and 2023 Day 10 Note Documents**\n\n" +
      "FinQ&A currently supports questions on text data from the listed documents. We are building capabilities to ask questions over the financial tables in these documents as well, along with expanding our knowledge repository to include more documents / document types.  Here are some tips:\n\n" +
      "#### Filter by Document Type First\n\n" +
      "For even more relevant responses, first use the filters to select the specific document type you want to query against (eg. **guidance**, **month-end close**, etc.). Then FinQ&A can focus its answer\n\n" +
      "#### Specify the Financial Metric\n\n" +
      "If asking about a particular financial metric like **revenue**, **profit margins**, **cash flow**, etc.. be sure to state that metric clearly in your question. This will help FinQ&A focus its response\n\n" +
      "#### Include the Time Period\n\n" +
      "Stating the time period you're asking about, whether a particular **quarter**, **year**, **date range**, etc. will allow FinQ&A to retrieve the most relevant data for that timeframe.\n\n" +
      "#### Specify the Region or Market\n\n" +
      "Indicating the specific **region** or **market** will get you a properly localized financial answer\n\n" +
      "#### Note Comparison Scenarios\n\n" +
      "If you want to analyze **changes over time** or **compare different scenarios**, make that clear in your question as well.\n\n" +
      "#### Putting It All Together\n\n" +
      "**Example:** In Q4, how does worldwide GMS compare to OP2?\n\n" +
      "With a bit of extra detail and context in your questions, FinQ&A can leverage its natural language capabilities and financial data access to provide highly accurate and insightful responses. Give these tips a try for best results!",
    exampleTask: "In Q4, how does worldwide GMS compare to OP2?",
    skillLink: "https://w.amazon.com/bin/view/StrategicFinance/FinQ&A#HWhoCanIContactToLearnMore3F",
    skillLabel: "FinQ&A",
    aboutLink: aboutLink,
    issueLink: "https://issues.amazon.com/issues/create?template=e79a325d-8d5a-47cc-a42b-2f68babc6af1",
  },
  "sna-web-mec": {
    skillName: "S&A WebMEC Finney",
    skillId: "sna-web-mec",
    skillSubTitle: "Your Month End Close chatbot tool",
    skillHeaderDescription: SA_WEBMEC_FINNEY_DISCLAIMER,
    skillDescription:
      "**S&A WebMEC Finney is a powerful GenAI assistant that can answer questions on a wide range of WebMEC topics. To get the most relevant and accurate responses, it’s helpful to provide some additional detail and context in your questions.**\n\n" +
      `##### Disclaimer: ${SA_WEBMEC_FINNEY_DISCLAIMER}\n\n` +
      "### Knowledge Repository\n\n" +
      "[S&A WebMEC User Guide](https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home)\n\n" +
      "Finney currently supports questions on text data from the knowledge repository. Here are some tips:\n\n" +
      "#### Activating the Chatbot\n\n" +
      "Ask Your Question: Simply type your question in the below chat box\n\n" +
      "#### Organization Related\n\n" +
      "Specify the organization name or region name\n\n" +
      "**Example:** How do I request access to CNOPEX-FOX organization?\n\n" +
      "#### Troubleshooting\n\n" +
      "Specify the exact error message from WebMEC\n\n" +
      '**Example:** I received "Allocation group requires at least two rows. Please add the remainder of your allocation entries to this JE Group." error message. What should I do?\n\n' +
      "#### General Tool Usage\n\n" +
      "Specify which section of WebMEC or the task you want to perform\n\n" +
      "**Example:** Could you share the steps to create Allocation Automation Rule?\n\n" +
      "#### Effective Interaction Tips\n\n" +
      "Be Specific: Clearly state your question to get the most accurate response.\n\n" +
      "Provide Context: Since ***Finney*** does not remember past interactions, include relevant details in each query.\n\n" +
      "#### Additional Support\n\n" +
      "If Finney is unable to answer your question, please submit a ticket to S&A WebMEC team for support. Or use Slack Support Channel to reach out to the S&A WebMEC team.\n\n" +
      "S&A WebMEC User Guide: [User Guide - S&A WebMEC](https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home)\n\n" +
      "Slack Support Channel: [#webmec-support-and-comms](https://amazon.enterprise.slack.com/archives/C05J36MH0JG)\n\n" +
      "SIM Ticket: [Create WebMEC Support Ticket](https://t.corp.amazon.com/create/templates/2e4594b3-fa73-413a-88fc-619b9070a32b)\n\n" +
      "#### Current Limitations\n\n" +
      "**Conversation Memory**: Finney does not retain conversation history. Each question starts fresh.\n\n" +
      "**Response Scope**: Currently, Finney can only answer questions related to the S&A WebMEC User Guide.\n\n" +
      "**Response Accuracy**: Finney is providing inconsistent responses to few of the tables within the User Guide.\n\n" +
      "#### Putting It All Together\n\n" +
      "With a bit of extra detail and context in your questions, Finney can leverage its natural language capabilities and S&A WebMEC data access to provide highly accurate and insightful responses. Give these tips a try for best results!",
    exampleTask: "What are the Submission Reasons for IN-General-OPEX-FOX organization?",
    skillLink: "https://amazon.enterprise.slack.com/archives/C05J36MH0JG",
    skillLabel: "S&A WebMEC Finney",
    aboutLink: aboutLink,
    issueLink: "https://issues.amazon.com/issues/create?template=27c72b7e-aeb9-4588-84fd-5c866290eec6",
  },
  "data-catalog-search": {
    skillName: "Data Catalog Search",
    skillId: "data-catalog-search",
    skillSubTitle: "Your Data Catalog Search chatbot tool",
    skillHeaderDescription: DATA_CATALOG_SEARCH_DISCLAIMER,
    skillDescription:
      "**Finney’s Data Catalog Search enables the users to explore and discover datasets in the [WWASF Data Catalog](https://datacatalog.finergy.amazon.dev/browse?Dataset%20Status%20=%20Active) by using natural language phrases.**\n\n" +
      "**Data Catalog Search uses a knowledge base that has an array of dataset metadata, such as schema, table, and column information,**\n\n" +
      "**which makes it easy for users to find datasets and explore details about them to aid in analysis, reporting, and decision making.**\n\n" +
      "#### Knowledge Repository\n\n" +
      "Data Catalog Search repository contains ‘active’ datasets in the [WWASF Data Catalog](https://datacatalog.finergy.amazon.dev/browse?Dataset%20Status%20=%20Active). This includes datasets from multiple\n\n" +
      "finance organizations, such as WW Operations Finance, NA Stores Finance, Physical Stores Finance, and WW Amazon\n\n" +
      "Stores Finance. Filter by ‘org’ in the catalog to see datasets offered by all organizations.\n\n" +
      "#### Prompting Tips\n\n" +
      "To get the most of Data Catalog Search, it is important to know how to “prompt” it. In other words, you need to type your\n\n" +
      "question/task with enough context and details of how you want Data Catalog Search to answer.\n\n" +
      "Quick tips:\n\n" +
      `* Frame questions like "Which table or dataset can I use for ....?" and make your questions/asks as specific as you can\n\n` +
      "* Mention ‘table’ instead of ‘data’ if you want to have the table details in your answer\n\n" +
      "* To get the exact schema/table location of a dataset, say ‘use fully qualified table name’ in your prompt\n\n" +
      "* To get neatly formatted responses, say ‘format your answer’ in your prompt\n\n" +
      "Please see the comprehensive [Data Catalog Search user guide](https://quip-amazon.com/mbItAf1eROUY#bEA9AAMIhd5) for additional tips.\n\n" +
      "#### Feedback and Support\n\n" +
      "For general feature requests and support, please use this SIM ticket [pending ticket folder to be created by ASF]." +
      "\n\n\n\n" +
      "For [Operations Finance data](https://tiny.amazon.com/19aawwv3v), please submit SIM tickets to the S&A team for [feature requests](https://tiny.amazon.com/1jm2tlom9) and [issues/bugs](https://tiny.amazon.com/sydt6vi2).\n\n" +
      "#### What’s Coming Soon\n\n" +
      "We will be continuously adding datasets, expanding the knowledge, and modifying metadata to provide more accurate information that covers broader business areas.\n\n" +
      "Please let us know any feedback by submitting SIM tickets and clicking thumbs up/down buttons on Finney’s responses. You can also join the [#finney-interest](https://amazon.enterprise.slack.com/archives/C073667G6AH) Slack channel to keep up to date and let us know your thoughts.\n\n" +
      `##### Disclaimer: ` +
      `Please note that Data Catalog Search is designed to provide information and support based on its programming and available data.\n\n` +
      `##### While it strives to be accurate and helpful, it may not always reflect the most current information or provide perfect responses.\n\n` +
      `##### Always double-check critical information from reliable sources. Use this skill as a supplementary resource, not as a sole source of guidance.\n\n`,
    exampleTask: "Which table contains dimensional details for ASINs on the Amazon marketplace?",
    skillLink: "https://issues.amazon.com/issues/create?template=bfcb7f08-36ae-4b70-9071-b273294404cc",
    skillLabel: "Data Catalog Search",
    aboutLink: aboutLink,
    issueLink: "https://issues.amazon.com/issues/create?template=17f4a295-29fd-4931-a25c-dacb30f286ce",
  },
};

export interface FinneyFinalResponse {
  messageId: string;
  finalAnswer: string;
  referencedSources: string[];
}

export const DISCLAIMER_POP_UP_CONTENT =
  "Hi, here is your weekly reminder on Finney usage guidelines.\n\n" +
  "By using Finney, you agree to the following:\n\n" +
  "- I understand that Finney uses generative AI and it may give inaccurate or inappropriate responses. " +
  "I will review and validate the information before I use it. " +
  "For more information see [Responsible AI.](https://w.amazon.com/bin/view/WWCFT/Products/Finney/ResponsibleAIPolicy/)\n\n" +
  "- I will follow my organization’s [data security and handling policies](https://policy.a2z.com/docs/6378/publication) " +
  "when using Amazon company data obtained from Finney skills.\n\n" +
  "- Finney skill/GenAI service owner is responsible for managing and auditing user access to the specific skills they own." +
  "If I need access to any of the skills available in Finney, I will contact relevant Finney skill owners.";

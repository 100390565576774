import React from "react";

import AIMessage from "./AIMessage";
import UserMessage from "./UserMessage";

export interface MessageOwner {
  isSelf: boolean;
  isAI: boolean;
  avatarUrl?: string;
  name: string;
  alias: string;
}

export interface ChatMessageProps {
  timestamp: string;
  messageId: string;
  message: string;
  skill: string;
  staticReferenceSources?: string[];
  referencedSources: string[]; // referenced sources may only have links, e.g. ["tables.csv", "DataCatalog-UserGuide.html"]
  citations: string[]; // citations may have text explaining the referenced sources, e.g. ["[0] In doc XYZ, there is ..."]
  owner: MessageOwner;
  autoSpeak: boolean;
}

/**
 * Renders the chat message in the UI and handles the feedbacks
 * @param chatMessageProps
 */
export function ChatMessage(chatMessageProps: ChatMessageProps) {
  // :: Rendering
  return (
    <>
      {chatMessageProps.owner.isAI && <AIMessage chatMessageProps={chatMessageProps} />}
      {!chatMessageProps.owner.isAI && <UserMessage chatMessageProps={chatMessageProps} />}
    </>
  );
}

import React from "react";

import { createRoot } from "react-dom/client";

import { JWT_TOKEN, saveUserPref, USER_ALIAS_KEY, USER_EMAIL_KEY } from "./amplify/AmplifyCache";
import { AmplifyClientProvider } from "./amplify/AmplifyClientProvider";
import { UserProfile } from "./common/types/UserProfile";
import App from "./routes";

async function login() {
  AmplifyClientProvider.initialize();
  const _user = await AmplifyClientProvider.authenticateUser();
  const token = await AmplifyClientProvider.getJWTToken();
  const userPayload = _user.getSignInUserSession()!.getIdToken().payload;
  const user: UserProfile = {
    isAuthorized: true,
    login: userPayload.identities[0].userId,
    displayName: userPayload.email.split("@")[0],
  };
  //TODO: use amplify to get user details instead of cache
  saveUserPref(JWT_TOKEN, token);
  saveUserPref(USER_ALIAS_KEY, user.login);
  saveUserPref(USER_EMAIL_KEY, userPayload.email);
  if (!_user) {
    console.info("Not Logged in Yet...");
    return;
  }
}

async function renderApp() {
  await login();
  const root = createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <div>
        <App />
      </div>
    </React.StrictMode>
  );
}

renderApp();

export const ALPHA_DOMAIN = "alpha";
export const BETA_DOMAIN = "beta";
export const PROD_DOMAIN = "prod";
export const PERSONAL_DOMAIN = "arakibu";
export const REGION = "us-east-1";

/**
 * @param stage
 * @return cognito domain for the stage
 */
export function getCognitoDomain(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "finney-arakibu.auth.us-east-1.amazoncognito.com";
    case ALPHA_DOMAIN:
      return "finney-alpha.auth.us-east-1.amazoncognito.com";
    case BETA_DOMAIN:
      return "finney-beta.auth.us-east-1.amazoncognito.com";
    case PROD_DOMAIN:
      return "finney-prod.auth.us-east-1.amazoncognito.com";
    default:
      return "localhost:3000"; // <-- Dev Account updates here
  }
}

/**
 * @param stage
 * @return redirect url for the stage
 */
export function getRedirectUrl(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "https://arakibu.finney.finergy.amazon.dev/";
    case ALPHA_DOMAIN:
      return "https://alpha.finney.finergy.amazon.dev/";
    case BETA_DOMAIN:
      return "https://beta.finney.finergy.amazon.dev/";
    case PROD_DOMAIN:
      return "https://prod.finney.finergy.amazon.dev/";
    default:
      return "http://localhost:3000";
  }
}

/**
 * @param stage
 * @return Cognito Identity pool id for the stage
 */
export function getIdentityPoolId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "us-east-1:ed43ef75-031d-443a-b88b-f0380c0d3ade";
    case ALPHA_DOMAIN:
      return "us-east-1:b8f3174e-89df-4a92-9695-a845b272cf63";
    case BETA_DOMAIN:
      return "us-east-1:bb794379-76da-4724-9926-417f01c418bf";
    case PROD_DOMAIN:
      return "us-east-1:d5a76df4-5b01-4c1c-a4ff-b7f549b3bd43";
    default:
      return "us-east-1:b8f3174e-89df-4a92-9695-a845b272cf63"; // using alpha backend for default
  }
}

/**
 * @param stage
 * @return Cognito User pool id for the stage
 */
export function getUserPoolId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "us-east-1_eKmVgH7PN";
    case ALPHA_DOMAIN:
      return "us-east-1_ZiMveAqz3";
    case BETA_DOMAIN:
      return "us-east-1_yqdlO0lif";
    case PROD_DOMAIN:
      return "us-east-1_7770itzku";
    default:
      return "us-east-1_ZiMveAqz3"; // using alpha backend for default
  }
}

/**
 * @param stage
 * @return User pool Web Client id for the stage
 */
export function getUserPoolWebClientId(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "6jl09hm3hceqofeo7vc7kc3nnu";
    case ALPHA_DOMAIN:
      return "4qgp7413smiuofe58e6u62h9h3";
    case BETA_DOMAIN:
      return "1hsif3p0eun16gngbjn7jcedm";
    case PROD_DOMAIN:
      return "57cb8h3iic4vpvs10bgo677s77";
    default:
      return "4qgp7413smiuofe58e6u62h9h3"; // Using alpha backend for default
  }
}

/**
 * @param stage
 * @return Web Orchestrator Lambda function URL
 */
export function getWebOrchestratorEndpoint(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "https://lkmnzkm23cs3wv53wqi25223sm0illoh.lambda-url.us-east-1.on.aws/";
    case ALPHA_DOMAIN:
      return "https://gr2xcaxvcpps53yhh37ztiwvce0fvmgr.lambda-url.us-east-1.on.aws/";
    case BETA_DOMAIN:
      return "https://ep7rays3qoevvokbcxigd5ulae0fsdkf.lambda-url.us-east-1.on.aws/";
    case PROD_DOMAIN:
      return "https://r3zcwn5xazzy3hxr4cwfvbwyxu0htfau.lambda-url.us-east-1.on.aws/";
    default:
      return "https://gr2xcaxvcpps53yhh37ztiwvce0fvmgr.lambda-url.us-east-1.on.aws/"; // Using alpha backend for default
  }
}

/**
 * @param stage
 * @return Web meatdata Lambda function URL
 */
export function getWebMetadataFeedbackEndpoint(stage: string): string {
  switch (stage.toLowerCase()) {
    case PERSONAL_DOMAIN:
      return "https://dviytddd36tq3arhfxr73molou0ijsge.lambda-url.us-east-1.on.aws/";
    case ALPHA_DOMAIN:
      return "https://dpelktrtthvgct5awk7pe2dnq40hfesb.lambda-url.us-east-1.on.aws/";
    case BETA_DOMAIN:
      return "https://gxjojdwatkziterjyhn4xf2pwu0ddafg.lambda-url.us-east-1.on.aws/";
    case PROD_DOMAIN:
      return "https://cmmij3ssww6rsn7ee24qt7m7au0mmbsm.lambda-url.us-east-1.on.aws/";
    default:
      return "https://dpelktrtthvgct5awk7pe2dnq40hfesb.lambda-url.us-east-1.on.aws/"; // Using alpha backend for default
  }
}
